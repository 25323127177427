import React from 'react';
import GithubIcon from '../icons/github.svg';
import LinkedInIcon from '../icons/linkedin.svg';
import MailIcon from '../icons/mail.svg';
import TwitterIcon from '../icons/twitter.svg';

const Landing = () => (
    <div className="flex flex-col content-center h-screen font-bold">
        <div className="md:my-auto md:container mx-8">
            <MyNameIsJonah />
            <ImAWebDeveloper />
            <SometimesIPlayMusic />
            <OthertimesIDrinkCoffee />
            <ContactMe />
        </div>
    </div>
);

const MyNameIsJonah = () => (
    <div className="bg-neon-red flex justify-between items-center my-2 px-2 md:my-4 md:px-8 text-3xl md:text-5xl lg:text-7xl">
        <p>Hi, my name is Jonah</p>
    </div>
);

const ImAWebDeveloper = () => (
    <div className="flex justify-between items-center my-2 px-2 md:my-4 md:px-8 bg-neon-yellow text-2xl md:text-4xl lg:text-6xl">
        <p>I'm a web developer</p>
    </div>
);

const SometimesIPlayMusic = () => (
    <div className="flex justify-between items-center my-2 px-2 md:my-4 md:px-8 bg-neon-green text-2xl md:text-3xl lg:text-5xl">
        <p>I play music</p>
    </div>
);
const OthertimesIDrinkCoffee = () => (
    <div className="flex justify-between items-center my-2 px-2 md:my-4 md:px-8 bg-neon-blue text-2xl md:text-2xl lg:text-4xl">
        <p>I drink coffee</p>
    </div>
);

const ContactMe = () => (
    <div className="flex justify-right items-center content-center my-2 px-2 md:my-4 md:px-8 bg-neon-purple text-2xl md:text-xl lg:text-3xl">
        <span>Contact me: </span>
        <a
            href="mailto:hello@jonahoh.co"
            className="ml-4 h-5 w-5 md:h-6 md:w-6 lg:h-9 lg:w-9 cursor-pointer"
        >
            <MailIcon fill="white" />
        </a>
        <a
            href="https://www.linkedin.com/in/ohjonah/"
            className="ml-4 h-5 w-5 md:h-6 md:w-6 lg:h-9 lg:w-9 cursor-pointer"
        >
            <LinkedInIcon fill="white" />
        </a>
        <a
            href="https://github.com/ohjonah"
            className="ml-4 h-5 w-5 md:h-6 md:w-6 lg:h-9 lg:w-9 cursor-pointer"
        >
            <GithubIcon fill="white" />
        </a>
        <a
            href="https://twitter.com/ohjonah"
            className="ml-4 h-5 w-5 md:h-6 md:w-6 lg:h-9 lg:w-9 cursor-pointer"
        >
            <TwitterIcon fill="white" />
        </a>
    </div>
);

export default Landing;
